'use client'

import SensayLogo from '@/public/logos/sensay-logo.svg'
import { Disclosure } from '@headlessui/react'
import { X } from '@phosphor-icons/react/dist/ssr'
import Image from 'next/image'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import UserProfileButton from './UserProfileButton'

interface IMenuBurger {
  title: string
  href: string
}

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  isHeroVisible?: boolean
}

const menuBurger: IMenuBurger[] = [
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Replicas',
    href: '/replicas',
  },
  {
    title: 'Pricing',
    href: '/pricing',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
]

export default function Header({ isHeroVisible = false, ...props }: HeaderProps) {
  return (
    <div className="header-wrapper sticky top-0 z-[99] w-full" {...props}>
      <Disclosure
        as="nav"
        className={twMerge(
          'bg-background/80 p-3 text-black backdrop-blur-0 backdrop-blur-md ui-open:h-[100dvh] ui-open:bg-background/80 ui-open:text-black md:ui-open:h-auto md:ui-open:bg-transparent',
          isHeroVisible && 'bg-transparent md:text-white md:ui-open:bg-transparent md:ui-open:text-white',
        )}
      >
        {({ close }: { close: () => void }) => {
          return (
            <div className="m-auto box-border flex max-w-6xl flex-wrap items-center justify-between">
              <Link
                onClick={close}
                href="/"
                className="relative z-[99] flex shrink-0 flex-row items-center gap-1 transition duration-300 hover:text-primary"
              >
                <SensayLogo
                  alt="Sensay"
                  className={twMerge('h-[32px] fill-current', isHeroVisible && 'fill-white ui-open:fill-current')}
                />
                <span
                  className={twMerge(
                    'beta-badge rounded-md bg-primary/10 px-2 py-1 text-xs font-medium text-primary ring-1 ring-inset ring-primary/20',
                    isHeroVisible &&
                      'text-white ring-white/20 ui-open:text-primary ui-open:ring-primary/20 md:ui-open:text-white md:ui-open:ring-white/20',
                  )}
                >
                  βeta
                </span>
              </Link>
              <div className="order-last hidden h-full basis-full ui-open:block md:relative md:order-none md:block md:h-auto md:basis-auto">
                <div className="absolute inset-x-0 bottom-0 top-36 flex h-full flex-col items-center justify-start gap-6 md:relative md:top-0 md:flex-row md:pt-0">
                  <ul className="m-0 flex flex-col items-center gap-6 p-0 md:flex-row md:justify-center md:py-0">
                    {menuBurger.map((item: IMenuBurger) => (
                      <li className="list-none" key={item.title}>
                        <Link
                          onClick={() => close()}
                          href={item.href}
                          className="mb-6 text-right text-2xl transition duration-300 hover:text-primary md:mb-0 md:text-center md:text-lg"
                        >
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <UserProfileButton transparent={true} />
                </div>
              </div>
              <div className="shrink-0 transition-opacity hover:opacity-75 md:hidden">
                <Disclosure.Button
                  className="flex h-[40px] w-[40px] px-[5px] py-[14px] focus:outline-none"
                  aria-hidden="true"
                  id="header-disclosure-button"
                >
                  <Image
                    src="/assets/mobile-bars.svg"
                    alt="burger"
                    width={28}
                    height={28}
                    className={twMerge(
                      'ui-open:hidden ui-not-open:block',
                      isHeroVisible ? 'filter-white-svg' : 'filter-black-svg',
                    )}
                  />
                  <X
                    className="relative top-[-8px] h-8 w-8 brightness-0 ui-open:block ui-not-open:hidden"
                    aria-hidden="true"
                  />
                </Disclosure.Button>
              </div>
            </div>
          )
        }}
      </Disclosure>
    </div>
  )
}
